@import '../../base';

.banner {
    width: $safe-width;
    max-height: 1920px;
    margin: auto;
    position: relative;
    height: 100vh;
    .img {
        margin-left: 85px;
        margin-top: 166px;
        width: 1227px;
        margin-bottom: 21px;
    }
    .title {
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        top: 20vh;
        font-size: 50px;
        font-weight: 500;
        color: #171717;
    }
}
