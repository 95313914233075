.explode {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    top: 60%;
    zoom: 0.3;
    width: 1656px;
    height: 856px;
    cursor: pointer;
    // background-size: 100% 100%;
    // background-image: url('./img/0.png');
    img {
        position: absolute;
        transition-property: transform;
        transition-duration: .5s;
        // transition-timing-function: cubic-bezier(0.1, 0.2, 0.8, 0.95);
    }
    .img0 {
        z-index: -1;
    }
    .img1 {
        top: 260px;
        left: 521px;
        //transform: translate3d(-100px, 60px, 100px);
        z-index: 99;
    }
    .img2 {
        top: 150px;
        left: 120px;
        //transform: translate3d(-50px, 30px, 50px);
    }
    .img3 {
        //display: none;
        left: 1200px;
        top: 435px;
    }
    .img4 {
        //display: none;
        left: 1095px;
        top: 210px;
    }

    .img5 {
        left: 1290px;
        top: -120px;
    }

    .img6 {
        top: -280px;
        left: 1100px;   
    }

    .img7 {
        left: 1220px;
        top: -80px;
    }

    .img8 {
        //display: none;
        left: 1520px;
        top: -230px;
        z-index: 1;
    }
    .img9 {
        left: 120px;
        top: -5px;
        z-index: -2;   
    }
    .img10 {
        left: 60px;
        top: -415px;
        z-index: -1;
    }
    .img11 {
        top: -300px;
        left: -5px;
        z-index: -1;
    }
    .img12 {
        top: -430px;
        left: 380px;
        z-index: -2;
    }
    .img13 {
        top: -410px;
        left: 110px;
        z-index: -1
    }
    .img14 {
        top: -335px;
        left: 1273px;
    }

    &.animated {
        .img1 {
            transform: translate3d(-200px, 120px, 200px);
        }
        .img2 {
            transform: translate3d(-100px, 60px, 100px);
        }
        .img3 {
            transform: translate3d(200px, 100px, 0px);
        }
        .img4 {
            transform: translate3d(100px, 50px, 0);
        }
        .img5 {
            transform: translate3d(300px, -120px, 0);
        }
        .img6 {
            transform: translate3d(140px, -100px, 0);
        }
        .img7 {
            transform: translate3d(230px, -120px, 0);
        }
        .img8 {
            transform: translate3d(230px, -120px, 0);
        }
        .img9 {
            transform: translate3d(-180px, -20px, 0);
        }
        .img10 {
            transform: translate3d(-160px, -100px, 0);
        }
        .img11 {
            transform: translate3d(-240px, -130px, 0);
        }
        .img12 {
            transform: translate3d(-180px, -130px, 0);
        }
        .img13 {
            transform: translate3d(0, -100px, 0);
        }
        .img14 {
            transform: translate3d(140px, -170px, 0);
        }
    }
}