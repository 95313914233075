@import '../../base';

.history {
    width: $safe-width;
    padding: 0 63px;
    box-sizing: border-box;
    margin: auto;
    &.middle {
        transform: scale(0.65);
        margin-top: -340px;
        margin-bottom: -260px;
    }
    h3 {
        font-size: 40px;
        font-weight: 500;
        color: #171717;
        padding-top: 185px;
    }
    .news {
        display: flex;
        margin-top: 116px;
        position: relative;
        img {
            width: 440px;
            height: 440px;
            border-radius: 100px;
            border-bottom-left-radius: 30px;
        }
        .content {
            flex-grow: 1;
            .date {
                line-height: 1em;
                font-size: 70px;
                font-weight: 500;
                color: #171717;
            }
            .desc {
                font-size: 20px;
                font-weight: 500;
                color: #171717;
                line-height: 30px;
                margin-top: 30px;
                width: 439px;
            }
        }
        .prev, .next {
            position: absolute;
            font-size: 42px;
            bottom: 114px;
            cursor: pointer;
            color: #0855F2;
            &:hover {
                color: $black;
            }
        }
        .prev {
            left: 0;
        }
        .next {
            left: 302px;
        }
    }
    .timeline-wrap {
        width: 100%;
        height: 20px;
        background: #E4E4E0;
        border-radius: 10px;
        margin-top: 30px;
        margin-bottom: 200px;
       
        position: relative;
        .timeline {
            display: flex;
            justify-content: space-between;
        }
        li {
            height: 20px;
            // background: #86AAF3;
            // background: #C5C5C1;
            // border-radius: 50%;
            position: relative;
            flex-grow: 1;
            &:first-child::before, &:last-child::before {
                content: '';
                position: absolute;
                width: 50%;
                top: 0;
                bottom: 0;
                display: block;
                background-color: #fff;
                z-index: 1;
            }
            &:first-child::before {
                left: 0;
            }
            &:last-child::before {
                right: 0;
            }
            &::after {
                content: '';
                position: absolute;
                height: 100%;
                width: 20px;
                background: #C5C5C1;
                border-radius: 50%;
                left: 50%;
                transform: translateX(-50%);
                z-index: 2;
                transition-property: background-color;
                transition-duration: .2s;
                cursor: pointer;
            }
            span {
                position: absolute;
                white-space: nowrap;
                top: 30px;
                left: 50%;
                transform: translateX(-50%);
                font-size: 20px;
                font-weight: 500;
                color: $black;
            }
            &.current::after {
                background: #0855F2;
            }
            &.active::after {
                background: #86AAF3;
            }
        }
        .passed {
            position: absolute;
            height: 100%;
            left: 0;
            top: 0;
            background: #C3D4F8;
            z-index: 1;
            transition-property: width;
        }
    }
}