@import '../../base';

.intorduce {
    width: $safe-width;
    margin: auto;
    position: relative;
    &.middle {
        .company {
            transform: scale(0.65);
            margin-top: -100px;
            margin-bottom: -150px;
        }
        .company-intorduct-pic-wrap {
            //margin: 0
            margin-bottom: 170px;
        }
        .team-intorduce {
            transform: scale(0.65);
            margin-top: -300px;
            margin-bottom: -100px;
        }

        .part {
            position: relative;
            top: -20px;
            transform: scale(0.65);
            padding-top: 0;
            &:nth-child(2) {
                top: -110px;
            }
        }
    }
    h3 {
        padding-top: 185px;
        padding-left: 63px;

        font-size: 40px;
        font-weight: 500;
        color: #171717;
    }
    .company-desc {
        position: absolute;
        width: 393px;
        top: 315px;
        right: 43px;

        font-size: 20px;
        font-weight: 400;
        color: #171717;
        line-height: 30px;
    }
    .company-intorduct-pic-wrap {
        margin-top: 46px;
        margin-left: -80px;
        margin-bottom: 258px;
        position: relative;
        .light {
            position: absolute;
            display: block;
            width: 50px;
            height: 50px;
            top: -132px;
            left: 494px;
            width: 315px;
            height: 315px;
            z-index: -1;
            background-image: url('./光效光波.svg');
        }
        &.middle {
            transform: scale(0.6);
        }
    }
    .team-intorduce {
        width: 1200px;
        height: 664px;
        font-size: 20px;
        color: #FFFFFF;
        line-height: 30px;
        background: url('./团队介绍.png');
        background-size: 100% 100%;
        margin: auto;
        position: relative;
        margin-bottom: 60px;
        border-radius: 30px;
        
        .content {
            position: absolute;
            bottom: 67px;
            left: 62px;
            right: 62px;
            .desc {
                font-size: 16px;
                line-height: 28px;
            }
            h5 {
                font-size: 40px;
                font-weight: 500;
                margin-bottom: 50px;
            }
        }
    }
    .part {
        display: flex;
        padding-top: 43px;
        img {
            width: 586px;
            border-radius: 30px;
        }
        .block {
            padding: 0 60px;
            h6 {
                padding-top: 89px;
                font-size: 40px;
                font-weight: 500;
                color: #171717;
            }
            .desc {
                padding-top: 50px;
                font-size: 20px;
                font-weight: 400;
                color: #171717;
                line-height: 30px;
                
            }
        }
        >*+* {
            margin-left: 30px;
        }
    }
}

