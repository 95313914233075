.city-v2 {
    position: relative;
    width: 100vw;
    height: 1295px;
    background: linear-gradient(0deg, #222222 0%, #18324F 100%);
    .img-wrap {
        position: absolute;
        top: 67px;
        left: 50%;
        transform: translateX(-50%);
        width: 1005px;
        .img {
            width: 100%;
            height: 100%;
        }
    }
}