@import '../../base';

$word-width: 460px;

.bg {
    width: 100vw;
    height: 1042px;
    background-size: 1920px 1042px;
    background-image: url('./bg.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #174CBA;
    &.middle {
        height: 834px;
        .test-report {
            .laiyin {
                top: 175px;
                transform: scale(0.6);
                transform-origin: top;
            }
            h5, .desc {
                padding-left: 138px;
            }
            h5.en {
                font-size: 26px;
                line-height: 30px;
                margin-bottom: -30px;
            }

        }
    }
}

.test-report {
    width: $safe-width;
    height: 100%;
    position: relative;
    margin: auto;

    h5 {
        width: $word-width;
        font-size: 30px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 48px;
        padding-top: 167px;
        &.en {
            font-size: 28px;
            line-height: 40px;
            text-align: left;
            word-break: break-all;
        }
    }

    .desc {
        padding-top: 70px;
        width: $word-width;
        font-size: 20px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 30px;
    }
    .laiyin {
        position: absolute;
        right: 40px;
        top: 130px;
        width: 570px;
        height: 809px;
        background-image: url('./laiyin.png');
        background-size: 100% 100%;
        border-radius: 30px;
        transform: scale(0.9);
        //transform-origin: top;
    }
}