@import '../../base';

.bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 83px;
    transition-property: background-color;
    transition-duration: 0.35s;
    z-index: 1000;
}
.header {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    width: $safe-width;
    z-index: 1;

    color: #FFFFFF;
    line-height: 83px;
}

.logo {
    height: 42px;
    width: 94px;
    background-image: url('./logo.svg');
    background-size: 100% 100%;
    margin-top: 19px;
    margin-right: 171px;
    &.logo-black {
        background-image: url('./logo-black.svg');
    }
}
.menu {
    display: flex;
    li+li {
        margin-left: 62px;
    }
    li a {
        color: inherit;
        text-decoration: inherit;
        &.active {
            font-weight: bold;
        }
    }
}

.language {
    position: absolute;
    right: 0;
    top: 0;
    .active {
        font-weight: bold;
    }
}

.menu, .language {
    cursor: pointer;
}