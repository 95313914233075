.banner {
    width: 100vw;
    height: 100vh;
    background-image: linear-gradient( -158deg, rgb(64,64,65) 0%, rgba(11,10,11,0.12) 88%, rgba(11,10,11, 0.12) 100%);
    //background-color: rgb(161, 161, 161);
    position: relative;
    .mask {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        //background-image: linear-gradient( -158deg, rgb(64,64,65) 0%, rgba(11,10,11,0.12) 88%, rgba(11,10,11, 0.12) 100%);
        background-image: rgba(0, 0, 0, .3);
    }
    video {
        position: absolute;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        object-fit: fill;
    }
    .title {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        color: #fff;

        h3 {
            font-size: 34px;
            font-family: MontserratAlternates;
            font-weight: 500;
            text-align: center;
            white-space: nowrap;
        }
        .desc {
            margin-top: 25px;
            font-size: 34px;
            font-weight: 500;
            font-family: HarmonyOS Sans SC;
            white-space: nowrap;
            text-align: center;
        }
    }
}