.hardware {
    background: linear-gradient(267deg, #025584 1%, #012739 100%);
    .title-pic {
        position: absolute;
        width: 611px;
        height: 350px;
        top: 10px;
        left: -70px;
        background-size: 100% 100%;
        background-image: url('./img2/自研硬件头部装饰.png');
        .car {
            position: absolute;
            left: 296px;
            top: 102px;
            width: 162px;
            height: 142px;
            background-size: 100% 100%;
            background-image: url('./img2/自研硬件小车.png');
        }
    }
}