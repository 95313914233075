.perception {
    background: linear-gradient(241deg, #22074A 0%, #1E1B21 100%);
    .title-pic {
        &::before {
            position: absolute;
            top: 71px;
            left: 0;
            content: '';
            display: block;
            width: 528px;
            height: 288px;
            background: url('./img/感知光圈.png');
            background-size: 100% 100%;
        }
        &::after {
            content: '';
            position: absolute;
            top: 216px;
            left: 272px;
            height: 111px;
            width: 134px;
            background: url('./img/感知头部小车.png');
            background-size: 100% 100%;
        }
    }
}