@import '../../base';

.card-wrap {
    &:nth-child(2n) {
        background: #F3F2F2;
    }
}
.card-wrap.middle {
    .card {
        transform: scale(0.65);
        transform-origin: top;
        padding-top: 120px;
        padding-bottom: 0;
        margin-bottom: 0;
        height: 620px;
        img {
            margin-top: 50px;
        }
        .line {
            .desc {
                width: 500px;
            }
        }
    }
}
.card {
    width: $safe-width;
    margin: auto;
    padding-top: 125px;
    padding-bottom: 110px;
    // transform: scale(0.65);
    .line {
        color: $black;
        display: flex;
        justify-content: space-between;
        .title {
            font-size: 40px;
            &.en {
                font-size: 36px;
            }
        }
        .desc {
            position: relative;
            top: -8px;
            width: 497px;
            font-size: 20px;
            line-height: 34px;
            &.en {
                font-size: 20px;
                line-height: 28px;
            }
        }
    }
    img {
        width: 1200px;
        height: 667px;
        display: block;
        margin: auto;
        margin-top: 80px;
        border-radius: 20px;
    }
}