@import '../../base';

.banner {
    height: 28.1vw;
    width: 100vw;
    //background: linear-gradient(241deg, #B39F83 0%, #0A162C 100%);
    background: #0C182A;
    position: relative;
    &::before {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        min-width: 1893px;
        top: 28px;
        bottom: 0;
        display: block;
        background: url('./关于我们头图.png');
        background-size: 1831px 493px;
        background-position: center center;
        background-repeat: no-repeat;
    }
    .content {
        width: $safe-width;
        height: 100%;
        position: relative;
        margin: auto;
        h3 {
            position: absolute;
            top: 50%;
            left: 62px;
            font-size: 50px;
            font-weight: 500;
            color: #FFFFFF;
        }
    }
}