@import '../../base';

.honor-wrapper {
    background: linear-gradient(50deg, #FFFFFF 0%, #EFF1F1 100%);
    &.middle {
        .honor {
            transform: scale(0.65);
            height: 650px;
            h3 {
                top: -50px;
            }
            .pics {
                top: 130px;
            }
            .dot {
                bottom: 30px;
            }
        }
       
    }
}
.honor {
    width: $safe-width;
    margin: auto;
    position: relative;
    height: 880px;

    h3 {
        position: absolute;
        top: 103px;
        left: 63px;
        font-size: 40px;
        font-weight: 500;
        color: #171717;
    }

    .pics {
        position: absolute;
        top: 250px;
        left: 63px;
        display: flex;
        li + li {
            margin-left: 8px;
        }
        img {
            width: 528px;
            border-radius: 20px;
        }
        img:hover {
            scale: 1.1;
            transition-property: scale;
            transition-duration: .3;
        }
        .name {
            padding-top: 23px;
            padding-left: 30px;
            padding-right: 30px;
            line-height: 28px;
            font-size: 20px;
            font-weight: 500;
            color: #171717;
            &.en {
                display: none;
            }
        }
    }
    .dot {
        position: absolute;
        bottom: 82px;
        left: 50%;
        transform: translate(-50%);
        display: flex;
        li {
            width: 8px;
            height: 8px;
            background: #96B5F6;
            border-radius: 50%;
            &.active {
                background: #0855F2;
            }
        }
        li + li {
            margin-left: 20px;
        }
    }
}