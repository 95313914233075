@import '../../base';

.banner-wrap {
    width: 100vw;
    min-height: 100vh;
    background: #232323;
    .banner {
        //width: $safe-width;
        margin: auto;
        font-size: 0;
        h3 {
            font-size: 40px;
            font-weight: 400;
            color: $white;
            text-align: center;
            padding-top: 184px;
        }
        img {
            width: 100vw;
            margin: auto;
            margin-top: 65px;
            transition-property: clipPath;
            transition-duration: .1s;
        }
        .desc {
            width: $safe-width;
            margin: auto;
            font-size: 20px;
            color: $white;
            line-height: 34px;
            margin-top: 65px;
            &.middle {
                width: $safe-width-md;
            }
            &.en {
                line-height: 32px;
            }
        }
    }
}