@import '../base';

.news-banner {
    height: 650px;
    width: 100vw;
    background: url('./bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    h3 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 50px;
        font-weight: 500;
        color: #FFFFFF;
    }
}

.center {
    width: $safe-width;
    margin: auto;
    padding: 100px 0;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;

    li {
        margin-left: 42px;
        &:nth-child(4n+1) {
            margin-left: 0;
        }
    }
    li {
        width: 267px;
        height: 387px;
        margin-top: 35px;
        position: relative;
        overflow: hidden;
        img {
            position: absolute;
            left: 50%;
            transform: translate(-50%);
            height: 267px;
        }
    }
    li:nth-child(1),
    li:nth-child(2),
    li:nth-child(3),
    li:nth-child(4) {
        margin-top: 0;
    }
    li {
        background: #F7F6F6;
        border-radius: 38px;
        cursor: pointer;
        .title {
            position: absolute;
            left: 0px;
            right: 0px;
            bottom: 60px;
            min-height: 80px;
            padding: 0 20px;
            padding-top: 15px;
            box-sizing: border-box;
            background: #F7F6F6;
            font-size: 17px;
            font-weight: 500;
            color: #171717;
            line-height: 22px;
            overflow: hidden;
            text-overflow: ellipsis;
            //white-space:nowrap;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            display: -webkit-box;
            word-break: break-all;
            transition-property: height;
            transition-duration: 1s;
            &.en {
                font-size: 14px;
                line-height: 20px;
                min-height: 70px;
            }
        }
        .date {
            position: absolute;
            left: 22px;
            bottom: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #171717;
        }
        &:hover {
            .title {
                height: fit-content;
                min-height: 80px;
                -webkit-line-clamp: 100;
                color: #0855F2;
                &.en {
                    min-height: 70px;
                }
                //border-radius: 20px 20px 0 0;
            }
        }
    }
    .button-wrap {
        width: 100%;
        text-align: center;
        margin-top: 128px;

        .btn {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 20px;
            color: #0855F2;
            margin: auto;
            border: 2px solid #0855F2;
            border-radius: 30px;
            width: fit-content;
            height: 40px;
            padding: 0 50px;
            line-height: 1em;
            transition-duration: .5s;
            transition-property: color;
            white-space: nowrap;
            cursor: pointer;
            .btn-icon {
                font-size: 33px;
                color: inherit;
                transition-duration: 0 !important;
                margin-left: 10px;
                &.collapse {
                    flex-direction: row-reverse;
                }
            }
            &:hover {
                color: #1f1f1f;
                border-color: #1f1f1f;
                &.btn-icon {
                    color: #1f1f1f;
                }
            }
        }
    }
}