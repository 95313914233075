.scheduler {
    background: linear-gradient(267deg, #192225 0%, #0E313E 100%);
    .title-pic {
        position: absolute;
        top: 0;
        left: -148px;
        height: 456px;
        width: 795px;
        background-image: url('./img/调度平台-顶部装饰.png');
        background-size: 100% 100%;
        .car1 {
            position: absolute;
            width: 130px;
            height: 95px;
            top: 12px;
            left: 134px;
            background: url('./img/调度平台-小车1\ .png');
            background-size: 100% 100%;
        }
        .car2 {
            position: absolute;
            width: 127px;
            height: 111px;
            top: 77px;
            left: 507px;
            background: url('./img/调度平台-小车2\ .png');
            background-size: 100% 100%;
        }
    }
}