@import '../../base';

.content {
    width: $safe-width;
    margin: auto;
    color: #fff;
    position: relative;
    &.middle {
        .header {
            position: relative;
            transform: scale(0.65);
            transform-origin: top;
            margin-bottom: -130px;
        }
        .pics {
            transform: scale(0.65);
            position: relative;
            top: -160px;
            margin-bottom: -350px;
        }
    }

    .title {
        position: relative;
        padding-top: 146px;
        font-size: 40px;
        padding-bottom: 198px;
        z-index: 1;
    }
    .desc {
        position: absolute;
        top: 140px;
        right: 0;
        width: 567px;
        font-size: 18px;
        line-height: 34px;
        &.en {
            line-height: 24px;
        }
    }
    
    ul.menu {
        display: flex;
        >li {
            width: 260px;
            flex-grow: 1;
            text-align: center;
            position: relative;
            color: #C2BEBE;
            cursor: pointer;
            height: 72px;
            line-height: 26px;
            font-size: 20px;
            padding-left: 15px;
            padding-right: 15px;
            transition-property: color;
            transition-delay: .2s;
            display: flex;
            align-items: center;
            justify-content: center;
            & .item {
                //display: none;
                text-align: center;
                &.en {
                    font-size: 18px;
                    line-height: 24px;
                }
            }
            & + li {
                margin-left: 43px;
            }
            // &:before {
            //     position: absolute;
            //     content: "";
            //     left: 0;
            //     right: 0;
            //     top: 0;
            //     bottom: 61px;
            //     border: 1px solid;
            //     border-bottom: none;
            //     border-radius: 16px;
            //     border-bottom-left-radius: 0;
            //     border-bottom-right-radius: 0;
            // }
            // &:after {
            //     position: absolute;
            //     content: "";
            //     left: 0;
            //     right: 0;
            //     top: 0;
            //     bottom: 0;
            //     border: 1px solid;
            //     border-bottom: none;
            //     border-radius: 16px;
            //     border-bottom-left-radius: 0;
            //     border-bottom-right-radius: 0;
            //     border-image: linear-gradient(180deg, #FFFFFF, transparent) 10 10;
            //     clip-path: inset(0 round 16px);
            //     transition-property: background-color;
            //     transition-delay: .2s;
                
            // }
            &:after {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                content: '';
                background-image: url('./标签未选-灰色到透明.svg');
                background-size: 100% 100%;
            }
            &.active {
                color: #fff;
                &::after {
                    background-image: url('./标签选中-白色到透明.svg');
                }
            }
        }
        &.three >li {
            &::after {
                background-image: url('./标签未选-灰色到透明-感知.svg');
            }
            &.active::after {
                background-image: url('./标签选中-白色到透明-感知.svg');
            }
        }
        &.four >li {
            &::after {
                background-image: url('./标签未选-灰色到透明.svg');
            }
            &.active::after {
                background-image: url('./标签选中-白色到透明.svg');
            }
        }
        &.six >li {
            padding-left: 0;
            padding-right: 0;
            &::after {
                background-image: url('./标签未选-灰色到透明-6.svg');
            }
            &.active::after {
                background-image: url('./标签选中-白色到透明-6.svg');
            }
        }
    }
    .img-wrap {
        position: relative;
        width: 1200px;
        height: 667px;
        cursor: pointer;
        >img {
            display: block;
            margin: auto;
            margin-top: 20px;
            width: 100%;
            height: 100%;
            //clip-path: inset(0 0% 0 0% round 20px);
        }
        > video {
            width: 100%;
            object-fit: fill;
        }
        ul.children-new {
            margin-top: 20px;
            box-sizing: border-box;
            padding: 0 10px;
            padding-top: 10px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            background: #05042B;
            width: 100%;
            height: 100%;
            position: relative;
            .mask {
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(5,4,43,0.8);
                z-index: 1;
                top: 0;
                left: 0;
                .label {
                    position: absolute;
                    width: 166px;
                    height: 40px;
                    background: rgba(16,16,16,0.8);
                    border-radius: 10px;
                    top: 100px;
                    right: 120px;
                    z-index: 3;
                    font-size: 18px;
                    color: #fff;
                    line-height: 40px;
                    text-align: center;
                }
            }
            img.detail {
                position: absolute;
                width: 1000px;
                height: 500px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 2;
                border-radius: 10px;
            }
            
            >li {
                width: 585px;
                height: 315px;
                position: relative;
                .title {
                    position: absolute;
                    right: 10px;
                    top: 10px;
                    width: 166px;
                    height: 40px;
                    padding: 0;
                    line-height: 40px;
                    text-align: center;
                    background: rgba(16,16,16,0.8);
                    border-radius: 10px;
                    font-size: 18px;
                    color: #FFFFFF;
                    pointer-events: none;
                }
                
                img {
                    width: 100%;
                    height: 100%;
                }
                &:nth-child(1) img {
                    //opacity: .3;
                    border-top-left-radius: 10px;
                }
                &:nth-child(2) img {
                    //opacity: .3;
                    border-top-right-radius: 10px;
                }
                &:nth-child(3) img {
                    //opacity: .3;
                    border-bottom-left-radius: 10px;
                }
                &:nth-child(4) img {
                    //opacity: .3;
                    border-bottom-right-radius: 10px;
                }
            }
        }
    }
    .msg {
        margin: auto;
        margin-top: 27px;
        font-size: 18px;
        line-height: 30px;
        text-align: left;
        padding-bottom: 140px;
        width: fit-content;
        &.en {
            line-height: 28px;
        }
    }
}