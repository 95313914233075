.simu {
    background: linear-gradient(267deg, #3E27B3 1%, #6B61F4 100%);
    .title-pic {
        position: absolute;
        top: 22px;
        left: -187px;
        width: 834px;
        height: 229px;
        background: url('./img2/仿真平台-头部光影.png');
        background-size: 100% 100%;
        &::before {
            position: absolute;
            content: '';
            top: 30px;
            left: 281px;
            width: 264px;
            height: 86px;
            background: url('./img2/仿真平台头部小车.png');
        }
    }
}