@import '../../base';

.join-us-wrap {
    position: relative;
    width: 100vw;
    height: 1427px;
    background: linear-gradient(0deg, #E8F3F8 0%, #FFFFFF 100%);
    .car {
        position: absolute;
        top: -165px;
        left: 50%;
        width: 660px;
        height: 330px;
        background: url('./car.png');
        background-size: 100% 100%;
    }
    .recruit-wrap {
        height: 100%;
        width: $safe-width;
        margin: auto;
        position: relative;
        h3 {
            padding-top: 223px;
            font-size: 50px;
            font-weight: 500;
            color: #171717;
            text-align: center;
        }
        ul {
            margin-top: 184px;
            max-width: 480px;
            width: fit-content;
            min-width: 425px;
            h6 {
                font-size: 36px;
                font-weight: 500;
                color: #171717;
                margin-bottom: 30px;
            }
            li {
                position: relative;
                width: 100%;
                padding: 50px 0;
                font-size: 22px;
                font-weight: 500;
                color: #171717;
                cursor: pointer;
                white-space: nowrap;
                &:hover {
                    color: #0855F2;
                }
                &::before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    height: 1px;
                    left: 0;
                    width: 100%;
                    background: #1f1f1f;
                }
            }
        }
        .mail {
            margin-top: 142px;
            font-size: 22px;
            font-weight: 500;
            color: #171717;
        }
        .join-us-bg {
            position: absolute;
            top: 454px;
            right: 20px;
            width: 586px;
            height: 586px;
            background: url('./join-us.png');
            background-size: 100% 100%;
        }
    }
}