.planning {
    background: linear-gradient(267deg, #022BA0 1%, #02102C 100%);
    .title-pic {
        position: absolute;
        top: 38px;
        left: -162px;
        width: 692px;
        height: 217px;
        background: url('./img2/决策规划头部.png');
        .car {
            position: absolute;
            top: 12px;
            left: 256px;
            width: 81px;
            height: 89px;
            background-image: url('./img2/决策规划-小车.png');
            background-size: 100% 100%;
        }
    }
}