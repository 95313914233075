@import '../../base';

.footer-wrap {
    background: linear-gradient(0deg, #174CBA 0%, #2866E7 100%);
    width: 100vw;
    height: 645px;
    .footer {
        width: $safe-width;
        height: 100%;
        margin: auto;
        position: relative;
        .logo {
            height: 66px;
            width: 148px;
            position: absolute;
            top: 87px;
            left: 0;
            background-image: url('../Header/logo.svg');
            background-size: 100% 100%;
        }
        .menu {
            position: absolute;
            top:264px;
            left: 0;
        }
        ul li {
            font-size: 20px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 26px;
            &:hover {
                color: #1f1f1f;
                cursor: pointer;
            }
            a {
                color: inherit;
                text-decoration: none;
            }

            & + li {
                margin-top: 32px;
            }
        }

        .us {
            position: absolute;
            top: 264px;
            left: 236px;
        }

        .contact {
            position: absolute;
            top: 264px;
            right: 0;
            li {
                display: flex;
                &:hover {
                    color: #fff;
                    cursor: default;
                }
                .value {
                    margin-left: 20px;
                    width: 380px;
                    line-height: 26px;
                }
                .key {
                    width: 100px;
                    text-align: right;
                }
            }
        }
        .line {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 143px;
            height: 1px;
            background: #fff;
        }
        .copyright {
            position: absolute;
            left: 0;
            bottom: 74px;
            font-size: 18px;
            font-weight: 400;
            color: #FFFFFF;
        }
        .wechat {
            position: absolute;
            right: 0;
            bottom: 74px;
            width: 26px;
            height: 21px;
            background-image: url('./icon.svg');
            background-size: 100% 100%;
            transition-property: background-image;
            transition-duration: .2s;
            cursor: pointer;
            &.middle {
                right: 150px;
            }
            &:hover {
                background-image: url('./icon-b.svg');
            }
            .code {
                position: absolute;
                width: 223px;
                height: 284px;
                background: url('./code.png');
                background-size: 100% 100%;
                top: -306px;
                left: 13px;
                transform: translateX(-50%);
                font-size: 18px;
                font-weight: 400;
                &.en span {
                    font-size: 14px;
                    font-weight: 500;
                    color: #1E1E1E;
                    line-height: 18px;
                }
                span {
                    opacity: 0;
                    display: block;
                    width: 180px;
                    position: absolute;
                    top: 215px;
                    left: 50%;
                    transform: translate(-50%);
                    text-align: center;
                    line-height: 24px;
                    transition-property: all;
                    transition-duration: .3;
                }
            }
        }
    }
}