.contact-banner {
    height: 100vh;
    width: 100vw;
    background: url('./bg.png');
    background-position: center center;
    background-size: 1920px 1414px;
    background-color: #030E1C;
    background-repeat: no-repeat;
    position: relative;
    color: #fff;

    .contact {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-height: 60vh;
        display: flex;
        flex-direction: column;
        h3 {
            font-size: 50px;
            font-weight: 500;
            line-height: 83px;
            text-align: center;
        }
        .block {
            width: 100%;
            flex-grow: 1;
        }
        .way {
            display: flex;
            .part {
                position: relative;
                width: 390px;
                padding: 0 65px;
                padding-top: 95px;
                box-sizing: border-box;
                >* {
                    text-align: center;
                    font-size: 20px;
                    line-height: 36px;
                }
                .name {
                    margin-bottom: 28px;
                }
                &+.part::before {
                    content: '';
                    position: absolute;
                    width: 1px;
                    height: 75px;
                    background-color: #fff;
                    display: flex;
                    top: 100px;
                    left: 0;
                }
            }
        }

        .icon {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%);
            width: 50px;
            background-size: 100% 100%;
        }

        .icon-tel {
            @extend .icon;
            height: 50px;
            background-image: url('./tel.svg');
        }
        .icon-mail {
            @extend .icon;
            height: 45px;
            background-image: url('./mail.svg');
        }
        .icon-address {
            @extend .icon;
            height: 44px;
            background-image: url('./address.svg');
        }
    }
}