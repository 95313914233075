@import '../../base';

.partner-wrap {
    padding: 178px 0;
    background: #F0F2F7;
    display: flex;
    .partner {
        width: $safe-width;
        margin: auto;
        display: flex;
        align-items: center;
        .wrap {
            width: 850px;
            overflow-x: hidden;
            overflow-y: visible;
        }
        .prev, .next {
            font-size: 42px;
            color: #0855F2;
            cursor: pointer;
            transition-duration: .5s;
            transition-property: color;
            &:hover {
                color: #171717;
            }
        }
        .prev {
            margin-left: 35px;
            margin-right: 20px;
        }
        .next {
            margin-left: 20px;
            margin-right: 35px;
        }
        ul {
            display: flex;
            width: 100%;
            height: fit-content;
            transition: all 3s linear;
            li {
                width: 200px;
                height: 84px;
                flex-shrink: 0;
                background-size: 100% 100%;
                border-radius: 15px;
                cursor: pointer;
                &+li {
                    margin-left: 17px;
                }
            }
        }

    }
    .title {
        font-size: 36px;
        font-weight: 400;
        color: #171717;
        white-space: nowrap;
    }
}