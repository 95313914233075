.deep-learn {
    background: linear-gradient(267deg, #0C47C4 1%, #2964E0 100%);
    .title-pic {
        position: absolute;
        width: 486px;
        height: 436px;
        background: url('./img2/深度学习头部曲线.png');
        background-size: 100% 100%;
        .car {
            position: absolute;
            top: 206px;
            left: 75px;
            width: 183px;
            height: 77px;
            background: url('./img2/深度学习头部小车.png');
            background-size: 100% 100%;
        }
    }
}